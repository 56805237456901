import React from "react";
import LayoutBox from "../layout";
import NotFoundPage from '../pages/notFound';

const Login = React.lazy(() => import(/* webpackPrefetch: true*/ '../pages/login')); // 登录
const Home = React.lazy(() => import(/* webpackPrefetch: true*/ '../pages/home')); //Home
const MyNode = React.lazy(() => import(/* webpackPrefetch: true*/ '../pages/myNode')); //我的节点
const BuyNode = React.lazy(() => import(/* webpackPrefetch: true*/ '../pages/buyNode')); //购买节点
const MyOrder = React.lazy(() => import(/* webpackPrefetch: true*/ '../pages/myOrder')); //我的订单
const MyRecharge = React.lazy(() => import(/* webpackPrefetch: true*/ '../pages/myRecharge')); //我的充值
const MyCenter = React.lazy(() => import(/* webpackPrefetch: true*/ '../pages/myCenter')); //个人中心
const MySpread = React.lazy(() => import(/* webpackPrefetch: true*/ '../pages/mySpread'));

const routes = [
  {
    path: '/',
    exact: true,
    meta: {
      title: '登录 - 优优科技',
      auth: false,
    },
    component: Login,
  },
  {
    path: '/',
    exact: true,
    component: LayoutBox,
    children: [
      {
        path: '/home',
        exact: true,
        meta: {
          title: '首页 - 优优科技',
          auth: true,
        },
        component: Home,
      },
      {
        path: '/my-node',
        exact: true,
        meta: {
          title: '我的节点 - 优优科技',
          auth: true,
        },
        component: MyNode,
      },
      {
        path: '/buy-node',
        exact: true,
        meta: {
          title: '购买节点 - 优优科技',
          auth: true,
        },
        component: BuyNode,
      },
      {
        path: '/my-order',
        exact: true,
        meta: {
          title: '我的订单 - 优优科技',
          auth: true,
        },
        component: MyOrder,
      },
      {
        path: '/my-recharge',
        exact: true,
        meta: {
          title: '我的充值 - 优优科技',
          auth: true,
        },
        component: MyRecharge,
      },
      {
        path: '/my-center',
        exact: true,
        meta: {
          title: '个人中心 - 优优科技',
          auth: true,
        },
        component: MyCenter,
      },
      {
        path: '/my-spread',
        exact: true,
        meta: {
          title: '推广返利 - 优优科技',
          auth: true,
        },
        component: MySpread,
      }
    ],
  },
  {
    path: '*',
    exact: false,
    meta: {
      title: '无此页面',
      auth: false,
    },
    component: NotFoundPage,
  },
];

export default routes;